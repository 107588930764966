@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  --size: 30px;
  width: var(--size);
  height: var(--size);
  border: 5px solid #888;
  border-right: 5px solid transparent;
  border-radius: var(--size);
  animation: rotate 700ms linear infinite;
}
