@import url('https://fonts.googleapis.com/css?family=Ubuntu:400i&display=swap');

body,
html,
main,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

main {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
