@import url('https://fonts.googleapis.com/css?family=Ubuntu:400i&display=swap');

body,
input,
button {
  color: #555;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
